import React from "react"
import Layout from "../components/layout"
import Img from "../components/image"
import {
  hero__bg,
  hero,
  hero__logobox,
  hero__logobox__text,
  hero__logobox__text_1,
  hero__logobox__text_2,
  hero__logobox__icons,
  hero__img,
  hero__logobox__title,
  timeline,
  card,
  left,
  right,
  content,
  experiance,
  experiance_bg,
  experiance__jobentry,
  experiance__jobentry_job,
} from "./resume.module.scss"

import { FormattedMessage, Link, useIntl } from "gatsby-plugin-intl"
import classes from "../pages/about.module.scss"

export default function Resume() {
  return (
    <Layout>
      <div className={`${hero__bg} ${"container"} `}>
        <section className={`${hero} `}>
          <div className={hero__logobox}>
            <img
              src={require("../images/pawelstyczen_logo2.svg")}
              alt="logo"
            ></img>
            <div
              className={`${hero__logobox__text} ${"animate"}`}
              data-animation="subtitle"
            >
              <p className={hero__logobox__text_1}>
                Web Developer
                <br /> IT Specialist <br />
                SCRUM master
              </p>
              <p className={hero__logobox__text_2}>
                <a href="mailto: contact@pawelstyczen.com">
                  Email: contact@pawelstyczen.com
                </a>{" "}
                <br /> <a href="tel:790680198">Phone: 790680198</a>
              </p>
              <div className={hero__logobox__icons}>
                <a
                  href="https://www.linkedin.com/in/pawe%C5%82-stycze%C5%84-12aa93147"
                  target="_blank"
                >
                  <img src={require("../images/linkedinicon.svg")}></img>
                </a>
                <a href="https://github.com/PawelStyczen" target="_blank">
                  <img src={require("../images/git.svg")}></img>
                </a>
              </div>
            </div>
            <div className={hero__img}>
              <Img filename="pawel_white.png"></Img>
            </div>
            <div
              className={`${hero__logobox__title} ${"animate"}`}
              data-animation="title"
            >
              <h2>PAWEŁ STYCZEń</h2>
            </div>
          </div>
          <p>
            Hi,
            <br /> Im a IT specialist with years of experiance. Up until
            reacently my speciality was mostly solving problems for large
            companies like IBM and currently Nordea. I was involved in
            troubleshooting program and networking issues, problem solving using
            powershell, bash, python scripting. My main focus recently was
            delivering value by automating program executions using automation
            schedulers such as uc4. In 2019 my couriosity has led me to start
            experimenting with HTML and CSS, this has started my new path of
            learning web technologies and languages. Currently im a freelance
            web developer that is looking for new oportunities to learn,
            challange myself but most important of all help others solve
            problems and build something beautiful with my new skillset.
          </p>
        </section>
      </div>

      <section className={classes.technologie}>
        <h2>Skills</h2>
        <div className={classes.technologie__box}>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/html5-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Html5</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/css3-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Css3</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/powershell.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Powershell</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/javascript.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Javascript</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/python.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Python</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/php-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Php</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/react-original.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>React</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/automation.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Uc4</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/aws.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>aws</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/gatsby.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Gatsby</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/graphql.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Graphql</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/sql.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Sql</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/git-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Github</p>
          </div>
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/nodejs-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>NodeJs</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/npm-original-wordmark.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Npm</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/redux-original.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Redux</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/sass-original.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Sass</p>
          </div>{" "}
          <div
            className={`${classes.technologie__box__item} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <img
              src={require("../images/wordpress-plain.svg")}
              className="animate"
              data-animation="fade"
            ></img>
            <p className={classes.technologie__box__item__txt}>Wordoress</p>
          </div>{" "}
        </div>
      </section>

      <div className={`${timeline}`}>
        <div className={`${card} ${left}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>31.07.2019</h2>
            <div className="animate" data-animation="fade">
              <Img filename="html.png"></Img>
            </div>
            <p>Build Responsive Real World Websites with HTML5 and CSS3</p>
          </div>
        </div>
        <div className={`${card} ${right}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>02.05.2020</h2>
            <div className="animate" data-animation="fade">
              <Img filename="advancedCss.png"></Img>
            </div>
            <p>Advanced CSS and Sass: Flexbox, Grid, Animations and More!</p>
          </div>
        </div>
        <div className={`${card} ${left}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>02.03.2020</h2>
            <div className="animate" data-animation="fade">
              <Img filename="Seo.png"></Img>
            </div>
            <p>Pozycjonowanie Od Podstaw + SEO dla WordPress</p>
          </div>
        </div>
        <div className={`${card} ${right}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>08.04.2021</h2>
            <div className="animate" data-animation="fade">
              <Img filename="react.png"></Img>
            </div>
            <p>React - The Complete Guide (incl Hooks, React Router, Redux)</p>
          </div>
        </div>
        <div className={`${card} ${left}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>04.05.2021</h2>
            <div className="animate" data-animation="fade">
              <Img filename="pwa.png"></Img>
            </div>
            <p>Progressive Web Apps (PWA) - The Complete Guide</p>
          </div>
        </div>

        <div className={`${card} ${right}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>20.05.2021</h2>
            <div className="animate" data-animation="fade">
              <Img filename="python_gettingStarted.png"></Img>
            </div>
            <p>python for everyone pt 1</p>
          </div>
        </div>

        <div className={`${card} ${left}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>18.06.2021</h2>
            <div className="animate" data-animation="fade">
              <Img filename="python_dataStructures.png"></Img>
            </div>
            <p>python data structures pt 2</p>
          </div>
        </div>

        <div className={`${card} ${right}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>13.08.2021</h2>
            <div className="animate" data-animation="fade">
              <Img filename="python_webData.png"></Img>
            </div>
            <p>python using python to access the web pt 3</p>
          </div>
        </div>

        <div className={`${card} ${left}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>28.08.2021</h2>
            <div className="animate" data-animation="fade">
              <Img filename="python_databases.png"></Img>
            </div>
            <p>python using databases with python pt 4</p>
          </div>
        </div>

        <div className={`${card} ${right}`}>
          s
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>28.08.2021</h2>
            <div className="animate" data-animation="fade">
              <Img filename="python_capstone.png"></Img>
            </div>
            <p>
              python capstone, retrieveing processing, visualising data. pt 5
            </p>
          </div>
        </div>
        <div className={`${card} ${left}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>24.01.2022</h2>
            <div className="animate" data-animation="fade">
              <Img filename="safe_PO_PM_5_1.png"></Img>
            </div>
            <p>Safe PO/PM</p>
          </div>
        </div>
        <div className={`${card} ${right}`}>
          <div
            className={`${content} ${"animate"}`}
            data-animation="neo-background-2--technologie"
          >
            <h2>28.02.2022</h2>
            <div className="animate" data-animation="fade">
              <Img filename="PrintAchievement.png"></Img>
            </div>
            <p>Safe Scrum Master</p>
          </div>
        </div>
      </div>

      <section className={`${experiance_bg} ${"container"} `}>
        <div className={experiance}>
          <h2>experiance</h2>
          <div className={experiance__jobentry}>
            <p className={experiance__jobentry_job}>
              Freelancer <br />
              2020 - present
            </p>
            <ul>
              <li>Html websites</li>
              <li>Wordpress template creation</li>
              <li>Gatsby js websites</li>
              <li>React applications</li>
            </ul>
          </div>
          <div className={experiance__jobentry}>
            <p className={experiance__jobentry_job}>
              Senior IT Specialist - UC4 Batch <br /> Nordea 2018.01 – present
            </p>
            <ul>
              <li>Designed and created UC4 workflows.</li>
              <li>
                Solved problems regarding malfunctioning scripts, UC4 jobs.
              </li>
              <li>
                Reverse engineered Windows Scripts, programs and implemented
                them in UC4 functionality.{" "}
              </li>
              <li>Solved problems regarding malfunctioning scripts</li>
              <li>
                Created scripts and programs (bash, bat, vbs, javaScript) to
                automate and solve problems.
              </li>
              <li>
                Helping the Team coordinate by facilitating Safe Agile events
                and implement agile practices as a Scrum Master.
              </li>
            </ul>
          </div>
          <a
            href="mailto:contact@pawelstyczen.com?Subject=Work offer"
            className="btn--resume animate"
            data-animation="neo-background-btn"
          >
            <FormattedMessage id="btn2" />
          </a>
        </div>
      </section>
    </Layout>
  )
}
